import React from 'react'
import { graphql } from 'gatsby'
import 'twin.macro'
// import contentParser from 'gatsby-wpgraphql-inline-images'

import Layout from '../../components/Layout'
import Container from '../../components/Container'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { formatYoastSeo } from '../../helpers'

const PageTemplate = ({ data, location }) => {
  const page = data.allWpPage.nodes[0]

  return (
    <Layout location={location}>
      <Seo
        title="No Title"
        postSchema={formatYoastSeo(data.wp.seo.contentTypes.post.schema).raw}
      />
      <Container tw="py-12">
        <div
          tw="prose max-w-full"
          dangerouslySetInnerHTML={{ __html: page.content }}
        >
          {/* {contentParser({ content: page.content }, inlineImagesOptions )} */}
        </div>
      </Container>
    </Layout>
  )
}

export default PageTemplate

export const query = graphql`
  query($id: String!) {
    wp {
      ...WpSeoSchema
    }
    allWpPage(filter: { id: { eq: $id } }) {
      nodes {
        title
        content
      }
    }
  }
`
